.body-container {
  width: 100vw;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100vh;
  cursor: default;
  .name-container {
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    padding: 10vh;
    width: 90vw;
    justify-content: center;
    font-size: 4vw;
    h1 {
      border: 0.1rem solid #bc13fe;
      border-radius: 2rem;
      padding: 0.4em;
      margin: 0px;
    }
  }
  .social-media {
    position: absolute;
    width: 30%;
    height: 25%;
    top: 70%;
    a,
    a:visited {
      color: inherit;
    }
    .linkedin {
      transform: rotate(0deg);
      border-radius: 20px;
      right: 0px;
    }
    .github {
      border-radius: 20px;
      transform: rotate(0deg);
    }
  }
}
.neon-text {
  color: #fff;
  font-family: "Yellowtail", sans-serif;
  text-shadow: 0 0 5px #fff, 0 0 7px #fff, 0 0 16px #fff, 0 0 33px #bb13fe99,
    0 0 72px #bb13fead, 0 0 80px #bb13fe6c, 0 0 85px #bb13fe27,
    0 0 101px #bb13fe14;
  // text-shadow: 0 0 4px #fff
  // text-shadow:
  //     /* White glow */
  //     0 0 7px #fff,
  //     0 0 10px #fff,
  //     0 0 21px #fff,
  //     /* Green glow */
  //     0 0 42px #f09,
  //     0 0 82px #f09,
  //     0 0 92px #f09,
  //     0 0 102px #f09,
  //     0 0 151px #f09;
}

@media screen and (max-width: 420px) {
  h1 {
    font-size: 15vw;
  }
  .body-container {
    .social-media {
      position: absolute;
      width: 75%;
      height: 15%;
      top: 70%;
    }
  }
}
