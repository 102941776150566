.navbar-container{
  height: 100vh;
  max-height: 100%;
  position: fixed;
  right: 20px;
  display: flex;
  nav{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .navbar-items{
      // border: 2px solid var(--primary-4);
      background: var(--primary-4);
      border-radius: 50%;
      height: 50px;
      margin-bottom: 20px;
      width: 50px;
    }
  }
}