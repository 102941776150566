.projectDisplay-container {
  margin: 0px;
  margin-top: 40px;
  max-width: 100%;
  width: 100vw;
  max-width: 100%;
  padding: 0px;
  color: #fff;
  .projects-header-neon {
    bottom: 80px;
    display: inline-block;
    position: relative;
    .projectsHeader {
      margin-top: 120px;
    }
    a {
      scroll-behavior: smooth;
      color: inherit;
      text-decoration: inherit;
    }
  }
  #select-projects {
    scroll-margin-top: 20px;
  }
  .project-container {
    margin: auto;
    margin-top: 20px;
    width: 75vw;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .select-projects {
    height: 25vh;
    display: flex;
    // align-items: center;
    justify-content: center;
    margin-top: 3rem;
    .project {
      border-radius: 20px;
      padding: 8px;
      cursor: pointer;
      margin-left: 20px;
      margin-right: 20px;
      height: 100%;
      width: 30%;
      text-align: center;
      .project-info{
      }
      h1 {
        margin: 0px;
        padding: 0px;
      }
      p {
        margin: 0px;
        padding: 0px;
      }
      a,
      a:visited {
        color: inherit;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .projectDisplay-container {
    .project-container {
      // This displays the currently viewed project
      margin: auto;
      margin-top: 20px;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .projects-header-neon {// Code for the Projects neon sign introducing the project-container
      .projectsHeader {
        margin-top: 0px;
      }
    }
    .select-projects { // Code for the project info box where you can select the project
      margin-top: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      .project {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
        margin-right: 20px;
        width: 30%;
        height: 100%;
        text-align: center;
        p{
          font-size: 8px;
        }
        h1{
          font-size: 12px;
        }
      }
    }
  }
}
