.resume-container {
  margin-top: 40px;
  margin-bottom: 40px;
  .download {
    display: none;
  }
  .resume {
    display: grid;
    justify-items: center;
    .resume-image{
      display: none;
    }
    iframe,
    embed {
      margin-top: 200px;
      height: 90vh;
      min-width: 60vw;
    }
    img {
      display: none;
    }
  }
  a {
    color: inherit;
  }
}

@media screen and (max-width: 900px) {
  .resume-container {
    .download {
      display: block;
      transform: rotate(40deg);
      right: 0px;
      margin-top: 20px;
    }
    .resume {
      margin-top: 100px;
      .iframe {
        display: none;
      }
      .resume-image {
        display: block;
        margin-top: 100px;
        position: relative;
        .angle {
          width: 6vw;
          position: absolute;
          color: black;
          top: 50%;
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-size: 2rem;
          .left {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.311);
            height: 5vh;
            width: 4vw;
            &:active {
              background-color: rgba(0, 0, 0, 0.6);
            }
          }
          .right {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.311);
            height: 5vh;
            width: 4vw;
            &:active {
              background-color: rgba(0, 0, 0, 0.6);
            }
          }
        }
        img {
          display: block;
          width: 60vw;
        }
      }
    }
  }
}
