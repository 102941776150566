@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,700;1,400&family=Yellowtail&display=swap');

:root{
  --red: #7D272B;
  --green: #192818;
  --gray: #98A8A1;
  --gold: #AD8F3F;
  --tan: #E1BB75;
  --primary-0: #363278;
  --primary-1: #9291A5;
  --primary-2: #605D8F;
  --primary-3: #171361;
  --primary-4: #080449;
}

body{
  /* background-color: var(--primary-0); */
  background: linear-gradient(rgba(3, 1, 10, 0.7), rgba(3, 1, 10, 0.7)), url("./wall222.jpg");
  color: white;
  /* background-repeat: no-repeat; */
  background-position: center;
  margin: 0px;
}

html {
  scroll-behavior: smooth;
}