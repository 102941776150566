.contact {
  margin-top: 100px;
  margin-bottom: 100px;
  .form-container {
    display: flex;
    justify-content: center;
    form {
      padding: 40px;
      border-radius: 20px;
      box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem #bc13fe,
        0 0 0.8rem #bc13fe, 0 0 2.8rem #bc13fe, inset 0 0 1.3rem #bc13fe;
      text-shadow: 0 0 7px #bc13fe, 0 0 10px #bc13fe, 0 0 21px #bc13fe,
        0 0 42px #bc13fe, 0 0 82px #bc13fe, 0 0 92px #bc13fe, 0 0 102px #bc13fe,
        0 0 151px #bc13fe;
      width: 50%;
      margin-top: 120px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .input-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        input {
          border: none;
          padding: 12px;
          width: 45%;
          margin-bottom: 8px;
          &::placeholder {
            color: black;
          }
          &:active,
          &:focus {
            border: none;
            outline: none;
          }
        }
      }
      textarea {
        border: none;
        width: 100%;
        &::placeholder {
          color: black;
        }
        &:active,
        &:focus {
          outline: none;
        }
      }
      button {
        cursor: pointer;
        padding: 22px;
        border: none;
        background-color: transparent;
        color: #fff;
        box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem #0fa,
          0 0 0.8rem #0fa, inset 0 0 1.3rem #0fa;
        text-shadow: 0 0 15px #bc13fe;
          margin-top: 12px;
      }
    }
  }
}
